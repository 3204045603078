import createTsdApi from "../../../client-api/createTsdApi";
import {
  Log,
  LogQueryParams,
  LogCreateParams,
  LogUpdateParams,
} from "../../../entities/Log/log";
import { TsdSearchParam } from "../fetch";

const logApi = createTsdApi<
  Log,
  LogQueryParams,
  LogCreateParams,
  LogUpdateParams
>("logs", (searchParamsObject) => {
  let searchParams: TsdSearchParam<LogQueryParams>[] = [];

  if (typeof searchParamsObject.action !== "undefined") {
    searchParams.push({
      key: "action",
      value: searchParamsObject.action,
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default logApi;
