import createTsdApi from "../../../client-api/createTsdApi";
import {
  PrintQueue,
  PrintQueueCreateParams,
  PrintQueueQueryParams,
  PrintQueueUpdateParams,
} from "../../../entities/PrintQueue/printQueue";
import { TsdSearchParam } from "../fetch";

const printQueueApi = createTsdApi<
  PrintQueue,
  PrintQueueQueryParams,
  PrintQueueCreateParams,
  PrintQueueUpdateParams
>("print-queue", (params) => {
  const search: TsdSearchParam<PrintQueueQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.isPrinted !== "undefined") {
    search.push({ key: "isPrinted", value: params.isPrinted ? "1" : "0" });
  }

  if (typeof params.type !== "undefined") {
    search.push({ key: "type", value: params.type });
  }

  if (typeof params.createdLte !== "undefined") {
    search.push({ key: "createdLte", value: params.createdLte });
  }

  return search;
});

export default printQueueApi;
