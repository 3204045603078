import createTsdApi from "../../../client-api/createTsdApi";
import {
  CreateGroupFavourite,
  GroupFavourite,
  GroupFavouriteQueryParams,
  UpdateGroupFavourite,
} from "../../../entities/groupFavourite";
import tsdFetch, { ITsdFetch, TsdSearchParam } from "../fetch";
import { ProductFavourite } from "../../../entities/productFavourite";
import chunkArray from "../../../helpers/chunkArray";

const groupFavouriteApi = createTsdApi<
  GroupFavourite,
  GroupFavouriteQueryParams,
  UpdateGroupFavourite,
  CreateGroupFavourite
>("group-favourites", (params) => {
  const search: TsdSearchParam<GroupFavouriteQueryParams>[] = [];

  if (typeof params.groupIds !== "undefined") {
    params.groupIds.forEach((id) => {
      search.push({
        key: "groupIds",
        value: String(id),
      });
    });
  }

  if (typeof params.productOriginalIds !== "undefined") {
    params.productOriginalIds.forEach((id) => {
      search.push({
        key: "productOriginalIds",
        value: String(id),
      });
    });
  }

  if (typeof params.productIds !== "undefined") {
    params.productIds.forEach((id) => {
      search.push({
        key: "productIds",
        value: String(id),
      });
    });
  }

  if (typeof params.limit !== "undefined") {
    search.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    search.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  if (typeof params.customerIds !== "undefined") {
    params.customerIds.forEach((id) => {
      search.push({
        key: "customerIds",
        value: String(id),
      });
    });
  }

  return search;
});

type CreateGroupFavouriteResponse = {
  newGroupFavourite: GroupFavourite;
  newProductFavourites: ProductFavourite[];
};

type CreateFunc = (
  newGroupFavourite: CreateGroupFavourite
) => ITsdFetch<CreateGroupFavouriteResponse>;

const create: CreateFunc = async (newGroupFavourite) => {
  const url = `/api/v1/group-favourites`;
  return tsdFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newGroupFavourite),
  });
};

const bulkCreate = (newGroupFavourites: CreateGroupFavourite[]) => {
  const url = "api/v1/bulk/add-group-favourites";

  const chunks = chunkArray(newGroupFavourites, 1000000);

  for (const chunk of chunks) {
    tsdFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(chunk),
    });
  }
};

const bulkDelete = (ids: number[]) => {
  const url = "api/v1/bulk/delete-group-favourites";

  const chunks = chunkArray(ids, 1000000);

  for (const chunk of chunks) {
    tsdFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(chunk),
    });
  }
};

export default { ...groupFavouriteApi, create, bulkCreate, bulkDelete };
