import createTsdApi from "../../../client-api/createTsdApi";
import {
  RemittanceInvoicePayment,
  RemittanceInvoicePaymentCreateParams,
  RemittanceInvoicePaymentQueryParams,
  RemittanceInvoicePaymentUpdateParams,
} from "../../../entities/RemittanceInvoicePayment/remittanceInvoicePayment";
import { TsdSearchParam } from "../fetch";

const remittanceInvoicePaymentApi = createTsdApi<
  RemittanceInvoicePayment,
  RemittanceInvoicePaymentQueryParams,
  RemittanceInvoicePaymentCreateParams,
  RemittanceInvoicePaymentUpdateParams
>("remittance-invoice-payments", (params) => {
  const search: TsdSearchParam<RemittanceInvoicePaymentQueryParams>[] = [];

  if (typeof params.payerOrgIds !== "undefined") {
    params.payerOrgIds.forEach((id) => {
      search.push({ key: "payerOrgIds", value: String(id) });
    });
  }

  if (typeof params.remittanceInvoiceIds !== "undefined") {
    params.remittanceInvoiceIds.forEach((id) => {
      search.push({ key: "remittanceInvoiceIds", value: String(id) });
    });
  }

  if (typeof params.creditIds !== "undefined") {
    params.creditIds.forEach((id) => {
      search.push({ key: "creditIds", value: String(id) });
    });
  }

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.paymentDateGte !== "undefined") {
    search.push({
      key: "paymentDateGte",
      value: params.paymentDateGte,
    });
  }

  if (typeof params.paymentDateLte !== "undefined") {
    search.push({
      key: "paymentDateLte",
      value: params.paymentDateLte,
    });
  }

  return search;
});

export default remittanceInvoicePaymentApi;
