import "fast-text-encoding";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { Provider } from "react-redux";
import moment from "moment";
import postMessageToParent from "./helpers/postMessageToParent";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import localeData from "dayjs/plugin/localeData";
import toTsdDateFormat from "@tsdir/common/helpers/dayjs-custom-plugins/toTsdDateFormat";
import fromTsdDateFormat from "@tsdir/common/helpers/dayjs-custom-plugins/fromTsdDateFormat";
import exportOutOfStockCsv from "helpers/exportOutOfStockCsv";
dayjs.extend(updateLocale);
dayjs.extend(toTsdDateFormat);
dayjs.extend(fromTsdDateFormat);
dayjs.extend(localeData);

moment.locale("en-gb", {
  week: {
    dow: 1,
  },
});

dayjs.updateLocale("en", {
  weekStart: 1,
});

const handleKeydown = (e: KeyboardEvent) => {
  if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key.toLowerCase() === "l") {
    postMessageToParent({ message: "quickSearch", command: "openModal" });
  }
};

window.addEventListener("keydown", handleKeydown);

const handleMessage = (e: MessageEvent) => {
  const { source, command } = e.data;

  if (source === "tsd") {
    if (command === "exportOutOfStock") {
      exportOutOfStockCsv();
    }
  }
};

window.addEventListener("message", handleMessage);

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("No root element found.");

const root = createRoot(rootElement);

root.render(
  <React.Fragment>
    <Provider store={store}>
      <App />
    </Provider>
  </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
