import createTsdApi from "../../../client-api/createTsdApi";
import {
  RemittanceInvoice,
  RemittanceInvoiceCreateParams,
  RemittanceInvoiceQueryParams,
  RemittanceInvoiceUpdateParams,
} from "../../../entities/remittanceInvoice";
import { TsdSearchParam } from "../fetch";

const remittanceInvoiceApi = createTsdApi<
  RemittanceInvoice,
  RemittanceInvoiceQueryParams,
  RemittanceInvoiceCreateParams,
  RemittanceInvoiceUpdateParams
>("remittance-invoices", (params) => {
  const search: TsdSearchParam<RemittanceInvoiceQueryParams>[] = [];

  if (typeof params.invoiceIds !== "undefined") {
    params.invoiceIds.forEach((id) => {
      search.push({
        key: "invoiceIds",
        value: String(id),
      });
    });
  }

  if (typeof params.ids !== "undefined") {
    params.ids.forEach((id) => {
      search.push({ key: "ids", value: String(id) });
    });
  }

  if (typeof params.limit !== "undefined") {
    search.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    search.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  if (typeof params.remittanceIds !== "undefined") {
    params.remittanceIds.forEach((id) => {
      search.push({
        key: "remittanceIds",
        value: String(id),
      });
    });
  }

  return search;
});

export default remittanceInvoiceApi;
