import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerRequestCorrespondence,
  CustomerRequestCorrespondenceCreateParams,
  CustomerRequestCorrespondenceQueryParams,
  CustomerRequestCorrespondenceUpdateParams,
} from "../../../entities/customerRequestCorrespondence";
import { TsdSearchParam } from "../fetch";

const customerRequestCorrespondenceApi = createTsdApi<
  CustomerRequestCorrespondence,
  CustomerRequestCorrespondenceQueryParams,
  CustomerRequestCorrespondenceCreateParams,
  CustomerRequestCorrespondenceUpdateParams
>("customer-request-correspondences", (params) => {
  const search: TsdSearchParam<CustomerRequestCorrespondenceQueryParams>[] = [];

  if (typeof params.requestIds !== "undefined") {
    params.requestIds.forEach((id) => {
      search.push({
        key: "requestIds",
        value: String(id),
      });
    });
  }

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  return search;
});

export default customerRequestCorrespondenceApi;
