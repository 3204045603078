import createTsdApi from "../../../client-api/createTsdApi";
import {
  Sector,
  SectorCreateParams,
  SectorQueryParams,
  SectorUpdateParams,
} from "../../../entities/sector";
import { TsdSearchParam } from "../fetch";

const sectorApi = createTsdApi<
  Sector,
  SectorQueryParams,
  SectorCreateParams,
  SectorUpdateParams
>("sectors", (params) => {
  const searchParams: TsdSearchParam<SectorQueryParams>[] = [];

  if (typeof params.name !== "undefined") {
    searchParams.push({ key: "name", value: params.name });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({ key: "offset", value: String(params.offset) });
  }

  return searchParams;
});

export default sectorApi;
