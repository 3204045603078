import createTsdApi from "../../../client-api/createTsdApi";
import {
  ReminderItem,
  ReminderItemCreateParams,
  ReminderItemQueryParams,
  ReminderItemUpdateParams,
} from "../../../entities/ReminderItem/reminderItem";
import { TsdSearchParam } from "../fetch";

const reminderItemApi = createTsdApi<
  ReminderItem,
  ReminderItemQueryParams,
  ReminderItemCreateParams,
  ReminderItemUpdateParams
>("reminder-items", (params) => {
  const search: TsdSearchParam<ReminderItemQueryParams>[] = [];

  if (typeof params.isActive !== "undefined") {
    search.push({ key: "isActive", value: params.isActive ? "1" : "0" });
  }

  if (typeof params.reminderIds !== "undefined") {
    params.reminderIds.forEach((id) => {
      search.push({ key: "reminderIds", value: String(id) });
    });
  }

  return search;
});

export default reminderItemApi;
