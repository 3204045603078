import createTsdApi from "../../../client-api/createTsdApi";
import {
  PrebuiltOrderItem,
  PrebuiltOrderItemQueryParams,
  PrebuiltOrderItemCreateParams,
  PrebuiltOrderItemUpdateParams,
} from "../../../entities/prebuiltOrderItem";
import { TsdSearchParam } from "../fetch";

const prebuiltOrderItemApi = createTsdApi<
  PrebuiltOrderItem,
  PrebuiltOrderItemQueryParams,
  PrebuiltOrderItemCreateParams,
  PrebuiltOrderItemUpdateParams
>("prebuilt-order-items", (searchParamsObject) => {
  let searchParams: TsdSearchParam<PrebuiltOrderItemQueryParams>[] = [];

  if (typeof searchParamsObject.prebuiltOrderId !== "undefined") {
    searchParams.push({
      key: "prebuiltOrderId",
      value: String(searchParamsObject.prebuiltOrderId),
    });
  }

  if (typeof searchParamsObject.prebuiltOrderIds !== "undefined") {
    searchParamsObject.prebuiltOrderIds.forEach((id) => {
      searchParams.push({
        key: "prebuiltOrderIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default prebuiltOrderItemApi;
