import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerToSupplierAccount,
  CustomerToSupplierAccountCreateParams,
  CustomerToSupplierAccountQueryParams,
  CustomerToSupplierAccountUpdateParams,
} from "../../../entities/customerToSupplierAccount";
import { TsdSearchParam } from "../fetch";

const customerToSupplierAccountApi = createTsdApi<
  CustomerToSupplierAccount,
  CustomerToSupplierAccountQueryParams,
  CustomerToSupplierAccountCreateParams,
  CustomerToSupplierAccountUpdateParams
>("customer-to-supplier-accounts", (params) => {
  const search: TsdSearchParam<CustomerToSupplierAccountQueryParams>[] = [];

  if (typeof params.allowAccess !== "undefined") {
    search.push({
      key: "allowAccess",
      value: params.allowAccess ? "1" : "0",
    });
  }

  if (typeof params.customerToSupplierIds !== "undefined") {
    params.customerToSupplierIds.forEach((id) => {
      search.push({
        key: "customerToSupplierIds",
        value: String(id),
      });
    });
  }

  if (typeof params.isDeleted !== "undefined") {
    search.push({
      key: "isDeleted",
      value: params.isDeleted ? "1" : "0",
    });
  }

  return search;
});

export default customerToSupplierAccountApi;
