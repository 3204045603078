import tsdFetch, { IMapFunction, ITsdFetch, TsdSearchParam } from "../fetch";
import { Product, ProductQueryParams } from "../../../entities/product";
import dayjs from "dayjs";
import fromTsdDateFormat from "../../../helpers/dayjs-custom-plugins/fromTsdDateFormat";

// workaround for dayjs not working properly on production
// due to a faulty check in the dayjs source
dayjs.extend((a, b, c) => fromTsdDateFormat(a, b, c));

const mapParamsToSearchParams: IMapFunction<ProductQueryParams> = (
  searchParamsObject
) => {
  let searchParams: TsdSearchParam<ProductQueryParams>[] = [];

  if (typeof searchParamsObject.includeAvailableStock !== "undefined") {
    searchParams.push({
      key: "includeAvailableStock",
      value: searchParamsObject.includeAvailableStock ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.status !== "undefined") {
    searchParams.push({
      key: "status",
      value: searchParamsObject.status,
    });
  }

  if (typeof searchParamsObject.isActiveAndCurrent !== "undefined") {
    searchParams.push({
      key: "isActiveAndCurrent",
      value: searchParamsObject.isActiveAndCurrent ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.brand !== "undefined") {
    searchParams.push({
      key: "brand",
      value: searchParamsObject.brand,
    });
  }

  if (typeof searchParamsObject.code !== "undefined") {
    searchParams.push({
      key: "code",
      value: searchParamsObject.code,
    });
  }

  if (typeof searchParamsObject.query !== "undefined") {
    searchParams.push({
      key: "query",
      value: searchParamsObject.query,
    });
  }

  if (typeof searchParamsObject.codeSearch !== "undefined") {
    searchParams.push({
      key: "codeSearch",
      value: searchParamsObject.codeSearch,
    });
  }

  if (typeof searchParamsObject.supplierIds !== "undefined") {
    searchParamsObject.supplierIds.forEach((id) => {
      searchParams.push({
        key: "supplierIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.priceFileIds !== "undefined") {
    searchParamsObject.priceFileIds.forEach((id) => {
      searchParams.push({
        key: "priceFileIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.originalIds !== "undefined") {
    searchParamsObject.originalIds.forEach((id) => {
      searchParams.push({
        key: "originalIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.priceGte !== "undefined") {
    searchParams.push({
      key: "priceGte",
      value: String(searchParamsObject.priceGte),
    });
  }

  if (typeof searchParamsObject.priceLte !== "undefined") {
    searchParams.push({
      key: "priceLte",
      value: String(searchParamsObject.priceLte),
    });
  }

  if (typeof searchParamsObject.productImageIds !== "undefined") {
    searchParamsObject.productImageIds.forEach((id) => {
      searchParams.push({
        key: "productImageIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.sortBy !== "undefined") {
    searchParams.push({
      key: "sortBy",
      value: String(searchParamsObject.sortBy),
    });
  }

  if (typeof searchParamsObject.cursorLastCode !== "undefined") {
    searchParams.push({
      key: "cursorLastCode",
      value: String(searchParamsObject.cursorLastCode),
    });
  }

  if (typeof searchParamsObject.cursorLastName !== "undefined") {
    searchParams.push({
      key: "cursorLastName",
      value: String(searchParamsObject.cursorLastName),
    });
  }

  if (typeof searchParamsObject.cursorLastParentId !== "undefined") {
    searchParams.push({
      key: "cursorLastParentId",
      value: String(searchParamsObject.cursorLastParentId),
    });
  }

  if (typeof searchParamsObject.cursorLastId !== "undefined") {
    searchParams.push({
      key: "cursorLastId",
      value: String(searchParamsObject.cursorLastId),
    });
  }

  if (typeof searchParamsObject.parentIds !== "undefined") {
    searchParamsObject.parentIds.forEach((id) => {
      searchParams.push({ key: "parentIds", value: String(id) });
    });
  }

  if (typeof searchParamsObject.hasChildren !== "undefined") {
    searchParams.push({
      key: "hasChildren",
      value: searchParamsObject.hasChildren ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.categoryIds !== "undefined") {
    searchParamsObject.categoryIds.forEach((id) => {
      searchParams.push({
        key: "categoryIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.subCategoryIds !== "undefined") {
    searchParamsObject.subCategoryIds.forEach((id) => {
      searchParams.push({
        key: "subCategoryIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.priceFileIds !== "undefined") {
    searchParamsObject.priceFileIds.forEach((id) => {
      searchParams.push({
        key: "priceFileIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.ids !== "undefined") {
    searchParamsObject.ids.forEach((id) => {
      searchParams.push({
        key: "ids",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.activeDateGte !== "undefined") {
    searchParams.push({
      key: "activeDateGte",
      value: dayjs()
        .fromTsdDateFormat(searchParamsObject.activeDateGte)
        .format("YYYY-MM-DD"),
    });
  }

  if (typeof searchParamsObject["favourites.customerIds"] !== "undefined") {
    searchParamsObject["favourites.customerIds"].forEach((id) => {
      searchParams.push({
        key: "favourites.customerIds",
        value: String(id),
      });
    });
  }

  if (
    typeof searchParamsObject["favourites.isOnlySiteFavouritesReturned"] !==
    "undefined"
  ) {
    searchParams.push({
      key: "favourites.isOnlySiteFavouritesReturned",
      value: searchParamsObject["favourites.isOnlySiteFavouritesReturned"]
        ? "1"
        : "0",
    });
  }

  if (
    typeof searchParamsObject["favourites.isOnlyNonSiteFavouritesReturned"] !==
    "undefined"
  ) {
    searchParams.push({
      key: "favourites.isOnlyNonSiteFavouritesReturned",
      value: searchParamsObject["favourites.isOnlyNonSiteFavouritesReturned"]
        ? "1"
        : "0",
    });
  }

  if (typeof searchParamsObject["customerIdsWithAccess"] !== "undefined") {
    searchParamsObject["customerIdsWithAccess"]
      .map(String)
      .forEach((customerIdWithAccess) => {
        searchParams.push({
          key: "customerIdsWithAccess",
          value: customerIdWithAccess,
        });
      });
  }

  if (typeof searchParamsObject.isHiddenFromCatalogueShown !== "undefined") {
    searchParams.push({
      key: "isHiddenFromCatalogueShown",
      value: searchParamsObject.isHiddenFromCatalogueShown ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.hasDatasheet !== "undefined") {
    searchParams.push({
      key: "hasDatasheet",
      value: searchParamsObject.hasDatasheet ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isCurrentOrFuture !== "undefined") {
    searchParams.push({
      key: "isCurrentOrFuture",
      value: searchParamsObject.isCurrentOrFuture ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.hasImage !== "undefined") {
    searchParams.push({
      key: "hasImage",
      value: searchParamsObject.hasImage ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isSupplierArchived !== "undefined") {
    searchParams.push({
      key: "isSupplierArchived",
      value: searchParamsObject.isSupplierArchived ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.exactMatchSearch !== "undefined") {
    searchParams.push({
      key: "exactMatchSearch",
      value: searchParamsObject.exactMatchSearch ? "1" : "0",
    });
  }

  return searchParams;
};

type QueryFunc = (parameters: ProductQueryParams) => ITsdFetch<Product[]>;

/**
 * Query server for products
 *
 * Example: get products with brand 'Brakes'
 *
 * ```js
 * query({
 *   brand: 'Brakes'
 * }).then((products) => {
 *   // products array
 * });
 * ```
 * @param parameters
 * @returns
 */
const query: QueryFunc = async (parameters) => {
  return tsdFetch(
    "api/v1/products",
    { method: "GET" },
    mapParamsToSearchParams(parameters)
  );
};

export default query;
