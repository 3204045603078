import {
  Budget,
  BudgetCreateParams,
  BudgetQueryParams,
} from "../../../entities/budget";
import createTsdApi from "../../../client-api/createTsdApi";
import { BudgetProductUpdateParams } from "../../../entities/BudgetProduct/budgetProduct";
import { TsdSearchParam } from "../fetch";
import dayjs from "dayjs";

const budgetApi = createTsdApi<
  Budget,
  BudgetQueryParams,
  BudgetCreateParams,
  BudgetProductUpdateParams
>("budgets", (params) => {
  const searchParams: TsdSearchParam<BudgetQueryParams>[] = [];

  ({
    categoryIds: (() => {
      if (typeof params.categoryIds !== "undefined") {
        params.categoryIds.forEach((id) => {
          searchParams.push({ key: "categoryIds", value: String(id) });
        });
      }
    })(),
    forProductCustomerId: (() => {
      if (typeof params.forProductCustomerId !== "undefined") {
        searchParams.push({
          key: "forProductCustomerId",
          value: String(params.forProductCustomerId),
        });
      }
    })(),
    forProductOriginalIds: (() => {
      if (typeof params.forProductOriginalIds !== "undefined") {
        params.forProductOriginalIds.forEach((id) => {
          searchParams.push({
            key: "forProductOriginalIds",
            value: String(id),
          });
        });
      }
    })(),
    forProductDate: (() => {
      if (typeof params.forProductDate !== "undefined") {
        searchParams.push({
          key: "forProductDate",
          value: dayjs()
            .fromTsdDateFormat(params.forProductDate)
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    forOrderItemCustomerId: (() => {
      if (typeof params.forOrderItemCustomerId !== "undefined") {
        searchParams.push({
          key: "forOrderItemCustomerId",
          value: String(params.forOrderItemCustomerId),
        });
      }
    })(),
    forOrderItemIds: (() => {
      if (typeof params.forOrderItemIds !== "undefined") {
        params.forOrderItemIds.forEach((id) => {
          searchParams.push({
            key: "forOrderItemIds",
            value: String(id),
          });
        });
      }
    })(),
    forOrderItemDate: (() => {
      if (typeof params.forOrderItemDate !== "undefined") {
        searchParams.push({
          key: "forOrderItemDate",
          value: dayjs()
            .fromTsdDateFormat(params.forOrderItemDate)
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    orgIds: (() => {
      if (typeof params.orgIds !== "undefined") {
        searchParams.push({
          key: "orgIds",
          value: String(params.orgIds),
        });
      }
    })(),
    status: (() => {
      if (typeof params.status !== "undefined") {
        searchParams.push({
          key: "status",
          value: params.status,
        });
      }
    })(),
    supplierIds: (() => {
      if (typeof params.supplierIds !== "undefined") {
        params.supplierIds.forEach((id) => {
          searchParams.push({ key: "supplierIds", value: String(id) });
        });
      }
    })(),
    budgetTypes: (() => {
      if (typeof params.budgetTypes !== "undefined") {
        params.budgetTypes.forEach((type) => {
          searchParams.push({ key: "budgetTypes", value: type });
        });
      }
    })(),
    isCurrent: (() => {
      if (typeof params.isCurrent !== "undefined") {
        searchParams.push({
          key: "isCurrent",
          value: params.isCurrent ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    forAmountDate: (() => {
      if (typeof params.forAmountDate !== "undefined") {
        searchParams.push({
          key: "forAmountDate",
          value: String(params.forAmountDate),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<BudgetQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default budgetApi;
