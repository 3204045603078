import {
  Remittance,
  RemittanceCreateParams,
  RemittanceQueryParams,
  RemittanceUpdateParams,
} from "../../../entities/Remittance/remittance";
import createTsdApi from "../../../client-api/createTsdApi";

import { TsdSearchParam } from "../fetch";

const remittanceApi = createTsdApi<
  Remittance,
  RemittanceQueryParams,
  RemittanceCreateParams,
  RemittanceUpdateParams
>("remittances", (searchParamsObject) => {
  let searchParams: TsdSearchParam<RemittanceQueryParams>[] = [];

  if (typeof searchParamsObject.payeeOrgId !== "undefined") {
    searchParams.push({
      key: "payeeOrgId",
      value: String(searchParamsObject.payeeOrgId),
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.status !== "undefined") {
    searchParams.push({
      key: "status",
      value: searchParamsObject.status,
    });
  }

  return searchParams;
});

export default remittanceApi;
