import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerNoteTagAttribute,
  CustomerNoteTagAttributeCreateParams,
  CustomerNoteTagAttributeQueryParams,
  CustomerNoteTagAttributeUpdateParams,
} from "../../../entities/CustomerNoteTagAttribute/customerNoteTagAttribute";
import { TsdSearchParam } from "../fetch";

const customerNoteTagAttributeApi = createTsdApi<
  CustomerNoteTagAttribute,
  CustomerNoteTagAttributeQueryParams,
  CustomerNoteTagAttributeCreateParams,
  CustomerNoteTagAttributeUpdateParams
>("customer-note-tag-attributes", (params) => {
  const search: TsdSearchParam<CustomerNoteTagAttributeQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.title !== "undefined") {
    search.push({ key: "title", value: params.title });
  }

  if (typeof params.tags !== "undefined") {
    params.tags.forEach((tag) => {
      search.push({
        key: "tags",
        value: tag,
      });
    });
  }

  if (typeof params.ids !== "undefined") {
    params.ids.map(String).forEach((id) => {
      search.push({
        key: "ids",
        value: id,
      });
    });
  }

  return search;
});

export default customerNoteTagAttributeApi;
