import createTsdApi from "../../../client-api/createTsdApi";
import {
  GroupSupplierCommissionOverride,
  GroupSupplierCommissionOverrideCreateParams,
  GroupSupplierCommissionOverrideQueryParams,
  GroupSupplierCommissionOverrideUpdateParams,
} from "../../../entities/GroupSupplierCommissionOverride/groupSupplierCommissionOverride";
import { TsdSearchParam } from "../fetch";

const groupSupplierCommissionOverrideApi = createTsdApi<
  GroupSupplierCommissionOverride,
  GroupSupplierCommissionOverrideQueryParams,
  GroupSupplierCommissionOverrideCreateParams,
  GroupSupplierCommissionOverrideUpdateParams
>("group-supplier-commission-overrides", (params) => {
  const search: TsdSearchParam<GroupSupplierCommissionOverrideQueryParams>[] =
    [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.groupIds !== "undefined") {
    params.groupIds.forEach((id) => {
      search.push({ key: "groupIds", value: String(id) });
    });
  }

  if (typeof params.supplierIds !== "undefined") {
    params.supplierIds.forEach((id) => {
      search.push({ key: "supplierIds", value: String(id) });
    });
  }

  if (typeof params.priceFileIds !== "undefined") {
    params.priceFileIds.forEach((id) => {
      search.push({ key: "priceFileIds", value: String(id) });
    });
  }

  return search;
});

export default groupSupplierCommissionOverrideApi;
