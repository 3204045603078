import createTsdApi from "../../../client-api/createTsdApi";
import {
  DiaryLog,
  DiaryLogCreateParams,
  DiaryLogQueryParams,
  DiaryLogUpdateParams,
} from "../../../entities/DiaryLog/diaryLog";
import { TsdSearchParam } from "../fetch";

const diaryLogApi = createTsdApi<
  DiaryLog,
  DiaryLogQueryParams,
  DiaryLogCreateParams,
  DiaryLogUpdateParams
>("diary-logs", (params) => {
  const search: TsdSearchParam<DiaryLogQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.diaryItemIds !== "undefined") {
    params.diaryItemIds.forEach((id) => {
      search.push({ key: "diaryItemIds", value: String(id) });
    });
  }

  if (typeof params.orgIds !== "undefined") {
    params.orgIds.forEach((id) => {
      search.push({ key: "orgIds", value: String(id) });
    });
  }

  if (typeof params.orderIds !== "undefined") {
    params.orderIds.forEach((id) => {
      search.push({ key: "orderIds", value: String(id) });
    });
  }

  return search;
});

export default diaryLogApi;
