import createTsdApi from "../../../client-api/createTsdApi";
import {
  PrebuiltOrder,
  PrebuiltOrderQueryParams,
  PrebuiltOrderCreateParams,
  PrebuiltOrderUpdateParams,
} from "../../../entities/prebuiltOrder";
import { TsdSearchParam } from "../fetch";

const prebuiltOrderApi = createTsdApi<
  PrebuiltOrder,
  PrebuiltOrderQueryParams,
  PrebuiltOrderCreateParams,
  PrebuiltOrderUpdateParams
>("prebuilt-orders", (searchParamsObject) => {
  let searchParams: TsdSearchParam<PrebuiltOrderQueryParams>[] = [];

  if (typeof searchParamsObject.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: searchParamsObject.isDeleted ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default prebuiltOrderApi;
