import createTsdApi from "../../../client-api/createTsdApi";
import {
  CreditGroup,
  CreditGroupCreateParams,
  CreditGroupQueryParams,
  CreditGroupUpdateParams,
} from "../../../entities/creditGroup";
import { TsdSearchParam } from "../fetch";

const creditGroupApi = createTsdApi<
  CreditGroup,
  CreditGroupQueryParams,
  CreditGroupCreateParams,
  CreditGroupUpdateParams
>("credit-groups", (searchParamsObject) => {
  let searchParams: TsdSearchParam<CreditGroupQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    idSearchString: (() => {
      if (typeof searchParamsObject.idSearchString !== "undefined") {
        searchParams.push({
          key: "idSearchString",
          value: searchParamsObject.idSearchString,
        });
      }
    })(),
    createdTimestampLte: (() => {
      if (typeof searchParamsObject.createdTimestampLte !== "undefined") {
        searchParams.push({
          key: "createdTimestampLte",
          value: searchParamsObject.createdTimestampLte.toISOString(),
        });
      }
    })(),
    createdTimestampGte: (() => {
      if (typeof searchParamsObject.createdTimestampGte !== "undefined") {
        searchParams.push({
          key: "createdTimestampGte",
          value: searchParamsObject.createdTimestampGte.toISOString(),
        });
      }
    })(),
    supplierId: (() => {
      if (typeof searchParamsObject.supplierId !== "undefined") {
        searchParams.push({
          key: "supplierId",
          value: String(searchParamsObject.supplierId),
        });
      }
    })(),
    customerId: (() => {
      if (typeof searchParamsObject.customerId !== "undefined") {
        searchParams.push({
          key: "customerId",
          value: String(searchParamsObject.customerId),
        });
      }
    })(),
    customerBudgetId: (() => {
      if (typeof searchParamsObject.customerBudgetId !== "undefined") {
        searchParams.push({
          key: "customerBudgetId",
          value: String(searchParamsObject.customerBudgetId),
        });
      }
    })(),
    categoryId: (() => {
      if (typeof searchParamsObject.categoryId !== "undefined") {
        searchParams.push({
          key: "categoryId",
          value: String(searchParamsObject.categoryId),
        });
      }
    })(),
    dateLte: (() => {
      if (typeof searchParamsObject.dateLte !== "undefined") {
        searchParams.push({
          key: "dateLte",
          value: searchParamsObject.dateLte.toISOString(),
        });
      }
    })(),
    dateGte: (() => {
      if (typeof searchParamsObject.dateGte !== "undefined") {
        searchParams.push({
          key: "dateGte",
          value: searchParamsObject.dateGte.toISOString(),
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof searchParamsObject.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: searchParamsObject.isDeleted ? "1" : "0",
        });
      }
    })(),
    outstanding: (() => {
      if (typeof searchParamsObject.outstanding !== "undefined") {
        searchParams.push({
          key: "outstanding",
          value: searchParamsObject.outstanding ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CreditGroupQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default creditGroupApi;
