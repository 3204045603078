import createTsdApi from "../../../client-api/createTsdApi";
import {
  HourlyCallStat,
  HourlyCallStatCreateParams,
  HourlyCallStatQueryParams,
  HourlyCallStatUpdateParams,
} from "../../../entities/HourlyCallStat/hourlyCallStat";
import tsdFetch, { TsdSearchParam } from "../fetch";

export type PutParams = {
  relevantUserId?: number;
  count?: number;
  isInbound?: boolean;
}[];

const hourlyCallStatApi = createTsdApi<
  HourlyCallStat,
  HourlyCallStatQueryParams,
  HourlyCallStatCreateParams,
  HourlyCallStatUpdateParams
>("hourly-call-stats", (params) => {
  const searchParams: TsdSearchParam<HourlyCallStatQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  if (typeof params.relevantUserIds !== "undefined") {
    params.relevantUserIds.forEach((id) => {
      searchParams.push({
        key: "relevantUserIds",
        value: String(id),
      });
    });

    if (typeof params.hourCommencingGte !== "undefined") {
      searchParams.push({
        key: "hourCommencingGte",
        value: params.hourCommencingGte,
      });
    }

    if (typeof params.hourCommencingLte !== "undefined") {
      searchParams.push({
        key: "hourCommencingLte",
        value: params.hourCommencingLte,
      });
    }
  }

  return searchParams;
});

const put = (hourCommencing: string, fields: PutParams) => {
  return tsdFetch(`api/v1/hourly-call-stats/${hourCommencing}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(fields),
  });
};

export default { ...hourlyCallStatApi, put };
