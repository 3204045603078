import createTsdApi from "../../../client-api/createTsdApi";
import {
  ScheduledTask,
  ScheduledTaskCreateParams,
  ScheduledTaskQueryParams,
  ScheduledTaskUpdateParams,
} from "../../../entities/ScheduledTask/scheduledTask";
import { TsdSearchParam } from "../fetch";

const scheduledTaskApi = createTsdApi<
  ScheduledTask,
  ScheduledTaskQueryParams,
  ScheduledTaskCreateParams,
  ScheduledTaskUpdateParams
>("scheduled-tasks", (params) => {
  const search: TsdSearchParam<ScheduledTaskQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.types !== "undefined") {
    params.types.forEach((type) => search.push({ key: "types", value: type }));
  }

  if (typeof params.isScheduled !== "undefined") {
    search.push({ key: "isScheduled", value: params.isScheduled ? "1" : "0" });
  }

  if (typeof params.scheduledForLte !== "undefined") {
    search.push({
      key: "scheduledForLte",
      value: params.scheduledForLte,
    });
  }

  return search;
});

export default scheduledTaskApi;
