import createTsdApi from "../../createTsdApi";
import {
  ProductAttributeProduct,
  ProductAttributeProductCreateParams,
  ProductAttributeProductQueryParams,
  ProductAttributeProductUpdateParams,
} from "../../../entities/ProductAttributeProduct/productAttributeProduct";
import { TsdSearchParam } from "../fetch";

const productAttributeProductApi = createTsdApi<
  ProductAttributeProduct,
  ProductAttributeProductQueryParams,
  ProductAttributeProductCreateParams,
  ProductAttributeProductUpdateParams
>("product-attribute-products", (searchParamsObject) => {
  const searchParams: TsdSearchParam<ProductAttributeProductQueryParams>[] = [];

  if (typeof searchParamsObject.productAttributeIds !== "undefined") {
    searchParamsObject.productAttributeIds.forEach((id) => {
      searchParams.push({
        key: "productAttributeIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.productIds !== "undefined") {
    searchParamsObject.productIds.forEach((id) => {
      searchParams.push({
        key: "productIds",
        value: String(id),
      });
    });
  }

  return searchParams;
});

export default productAttributeProductApi;
