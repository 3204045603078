import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerToSupplier,
  CustomerToSupplierCreateParams,
  CustomerToSupplierUpdateParams,
  CustomerToSupplierQueryParams,
} from "../../../entities/customerToSupplier";
import { TsdSearchParam } from "../fetch";

const customerToSupplierApi = createTsdApi<
  CustomerToSupplier,
  CustomerToSupplierQueryParams,
  CustomerToSupplierCreateParams,
  CustomerToSupplierUpdateParams
>("customer-to-suppliers", (params) => {
  const searchParams: TsdSearchParam<CustomerToSupplierQueryParams>[] = [];


  if (typeof params.customerIds !== "undefined") {
    params.customerIds.forEach((id) => {
      searchParams.push({ key: "customerIds", value: String(id) });
    });
  }

  if (typeof params.supplierIds !== "undefined") {
    params.supplierIds.forEach((id) => {
      searchParams.push({ key: "supplierIds", value: String(id) });
    });
  }

  if (typeof params.isActive !== "undefined") {
    searchParams.push({ key: "isActive", value: params.isActive ? "1" : "0" });
  }

  if (typeof params.isInactive !== "undefined") {
    searchParams.push({
      key: "isInactive",
      value: params.isInactive ? "1" : "0",
    });
  }

  if (typeof params.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: params.isDeleted ? "1" : "0",
    });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({ key: "offset", value: String(params.offset) });
  }

  return searchParams;
});

export default customerToSupplierApi;
