import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerOrgContact,
  CustomerOrgContactCreateParams,
  CustomerOrgContactQueryParams,
  CustomerOrgContactUpdateParams,
} from "../../../entities/CustomerOrgContact/customerOrgContact";
import { TsdSearchParam } from "../fetch";

const customerOrgContactApi = createTsdApi<
  CustomerOrgContact,
  CustomerOrgContactQueryParams,
  CustomerOrgContactCreateParams,
  CustomerOrgContactUpdateParams
>("customer-org-contacts", (params) => {
  const searchParams: TsdSearchParam<CustomerOrgContactQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }
  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }
  if (typeof params.isInvoiceContact !== "undefined") {
    searchParams.push({
      key: "isInvoiceContact",
      value: params.isInvoiceContact ? "1" : "0",
    });
  }
  if (typeof params.orgIds !== "undefined") {
    params.orgIds.forEach((id) => {
      searchParams.push({
        key: "orgIds",
        value: String(id),
      });
    });
  }
  if (typeof params.contactIds !== "undefined") {
    params.contactIds.forEach((id) => {
      searchParams.push({
        key: "contactIds",
        value: String(id),
      });
    });
  }

  return searchParams;
});

export default customerOrgContactApi;
