import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerContact,
  CustomerContactCreateParams,
  CustomerContactQueryParams,
  CustomerContactUpdateParams,
} from "../../../entities/CustomerContact/customerContact";
import tsdFetch, { TsdSearchParam } from "../fetch";

const customerContactApi = createTsdApi<
  CustomerContact,
  CustomerContactQueryParams,
  CustomerContactCreateParams,
  CustomerContactUpdateParams
>("customer-contacts", (params) => {
  const searchParams: TsdSearchParam<CustomerContactQueryParams>[] = [];

  ({
    isActive: (() => {
      if (typeof params.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: params.isActive ? "1" : "0",
        });
      }
    })(),
    isInactive: (() => {
      if (typeof params.isInactive !== "undefined") {
        searchParams.push({
          key: "isInactive",
          value: params.isInactive ? "1" : "0",
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: params.isDeleted ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    searchTerm: (() => {
      if (typeof params.searchTerm !== "undefined") {
        searchParams.push({
          key: "searchTerm",
          value: params.searchTerm,
        });
      }
    })(),
    groupIds: (() => {
      if (typeof params.groupIds !== "undefined") {
        params.groupIds.forEach((groupId) => {
          searchParams.push({
            key: "groupIds",
            value: String(groupId),
          });
        });
      }
    })(),
    orgIds: (() => {
      if (typeof params.orgIds !== "undefined") {
        params.orgIds.forEach((id) => {
          searchParams.push({
            key: "orgIds",
            value: String(id),
          });
        });
      }
    })(),
    telephoneSearchTerm: (() => {
      if (typeof params.telephoneSearchTerm !== "undefined") {
        searchParams.push({
          key: "telephoneSearchTerm",
          value: params.telephoneSearchTerm,
        });
      }
    })(),
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) => {
          searchParams.push({
            key: "ids",
            value: String(id),
          });
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerContactQueryParams>]: void | undefined;
  };

  return searchParams;
});

const sendStatement = (
  url: string,
  options: {
    type: "site" | "group";
    contactId: number;
    email: string;
    statementDate?: string;
    customerId?: number;
    groupId?: number;
  }
) => {
  return tsdFetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "report-type": (() => {
        if (options.type === "site") return "site statement";
        else if (options.type === "group") return "group statement";
      })(),
      "contact-id": options.contactId,
      "email-addresses": [options.email],
      "customer-id": options.customerId,
      "group-id": options.groupId,
      "statement-date": options.statementDate,
    }),
  });
};

export default { ...customerContactApi, sendStatement };
