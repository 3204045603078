import createTsdApi from "../../../client-api/createTsdApi";
import {
  PrebuiltOrderCustomer,
  PrebuiltOrderCustomerQueryParams,
  PrebuiltOrderCustomerCreateParams,
  PrebuiltOrderCustomerUpdateParams,
} from "../../../entities/prebuiltOrderCustomer";
import { TsdSearchParam } from "../fetch";

const prebuiltOrderCustomerApi = createTsdApi<
  PrebuiltOrderCustomer,
  PrebuiltOrderCustomerQueryParams,
  PrebuiltOrderCustomerCreateParams,
  PrebuiltOrderCustomerUpdateParams
>("prebuilt-order-customers", (searchParamsObject) => {
  let searchParams: TsdSearchParam<PrebuiltOrderCustomerQueryParams>[] = [];

  if (typeof searchParamsObject.customerId !== "undefined") {
    searchParams.push({
      key: "customerId",
      value: String(searchParamsObject.customerId),
    });
  }

  if (typeof searchParamsObject.customerIds !== "undefined") {
    searchParamsObject.customerIds.forEach((id) => {
      searchParams.push({
        key: "customerIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.prebuiltOrderId !== "undefined") {
    searchParams.push({
      key: "prebuiltOrderId",
      value: String(searchParamsObject.prebuiltOrderId),
    });
  }

  if (typeof searchParamsObject.prebuiltOrderIds !== "undefined") {
    searchParamsObject.prebuiltOrderIds.forEach((id) => {
      searchParams.push({
        key: "prebuiltOrderIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default prebuiltOrderCustomerApi;
