import createTsdApi from "../../../client-api/createTsdApi";
import {
  JobToPrintQueue,
  JobToPrintQueueCreateParams,
  JobToPrintQueueQueryParams,
  JobToPrintQueueUpdateParams,
} from "../../../entities/JobToPrintQueue/jobToPrintQueue";
import { TsdSearchParam } from "../fetch";

const jobToPrintQueueApi = createTsdApi<
  JobToPrintQueue,
  JobToPrintQueueQueryParams,
  JobToPrintQueueCreateParams,
  JobToPrintQueueUpdateParams
>("job-to-print-queues", (params) => {
  const search: TsdSearchParam<JobToPrintQueueQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.jobIds !== "undefined") {
    params.jobIds.forEach((id) => ({
      key: "jobIds",
      value: String(id),
    }));
  }

  if (typeof params.printQueueIds !== "undefined") {
    params.printQueueIds.forEach((id) => ({
      key: "printQueueIds",
      value: String(id),
    }));
  }

  return search;
});

export default jobToPrintQueueApi;
